import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AuthStyles.css';

// Helper function to safely use sessionStorage
const getFromStorage = (key) => {
  if (typeof window !== 'undefined' && window.sessionStorage) {
    return window.sessionStorage.getItem(key);
  }
  return null;
};

function LoginEmail() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  
  const handleNext = () => {
    if (!email.trim()) {
      setError('Email is required');
      return;
    }
    
    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address');
      return;
    }
    
    // Verify email against stored value
    const storedEmail = getFromStorage('auth_email');
    
    if (email === storedEmail) {
      // Move to security question verification
      navigate('/authentication-hell/login-security');
    } else {
      setError('Email does not match our records');
    }
  };

  return (
    <div className="auth-hell-form-container">
      <div className="auth-hell-header-group">
        <h3 className="auth-hell-header-text">verify your email</h3>
      </div>
      
      {error && (
        <div className="auth-hell-error-container">
          <p className="auth-hell-error-text">{error}</p>
        </div>
      )}
      
      <div className="auth-hell-input-group">
        <label className="auth-hell-input-label">email address</label>
        <input
          className="auth-hell-input"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setError('');
          }}
          placeholder="youremail@example.com"
          type="email"
        />
      </div>
      
      <div className="auth-hell-cursor-button-container">
        <button className="auth-hell-cursor-button" onClick={handleNext}>
          <div className="auth-hell-cursor"></div>
        </button>
      </div>
    </div>
  );
}

export default LoginEmail; 