import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AuthStyles.css';

function SignupSuccess() {
  const navigate = useNavigate();
  
  return (
    <div className="auth-hell-form-container">
      <div className="auth-hell-header-group">
        <h3 className="auth-hell-header-text">signup successful</h3>
      </div>
      
      <div className="auth-hell-success-container">
        <p className="auth-hell-success-text">
          Your account has been created successfully!
        </p>
        <p className="auth-hell-success-text">
          You can now login with your credentials.
        </p>
      </div>
      
      <div className="auth-hell-link-container">
        <button 
          className="auth-hell-link-button" 
          onClick={() => navigate('/authentication-hell/login')}
        >
          go to login
        </button>
      </div>
    </div>
  );
}

export default SignupSuccess; 