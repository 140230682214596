import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AuthStyles.css';

// Helper function to safely use sessionStorage
const saveToStorage = (key, value) => {
  if (typeof window !== 'undefined' && window.sessionStorage) {
    window.sessionStorage.setItem(key, value);
  }
};

function Signup() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  
  const handleSignup = () => {
    if (!username.trim() || !password.trim()) {
      setError('Username and password are required');
      return;
    }
    
    // Store credentials in sessionStorage
    saveToStorage('auth_username', username);
    saveToStorage('auth_password', password);
    
    // Navigate to pin screen
    navigate('/authentication-hell/signup-pin');
  };

  return (
    <div className="auth-hell-form-container">
      <div className="auth-hell-header-group">
        <h3 className="auth-hell-header-text">Signup</h3>
      </div>
      
      {error && (
        <div className="auth-hell-error-container">
          <p className="auth-hell-error-text">{error}</p>
        </div>
      )}
      
      <div className="auth-hell-input-group">
        <label className="auth-hell-input-label">username</label>
        <input
          className="auth-hell-input"
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
            setError('');
          }}
          placeholder=""
        />
      </div>
      
      <div className="auth-hell-input-group">
        <label className="auth-hell-input-label">password</label>
        <input
          className="auth-hell-input"
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setError('');
          }}
          placeholder=""
        />
      </div>
      
      <div className="auth-hell-cursor-button-container">
        <button className="auth-hell-cursor-button" onClick={handleSignup}>
          <div className="auth-hell-cursor"></div>
        </button>
      </div>
      
      <div className="auth-hell-link-container">
        <button 
          className="auth-hell-link-button" 
          onClick={() => navigate('/authentication-hell')}
        >
          back to home
        </button>
      </div>
    </div>
  );
}

export default Signup; 