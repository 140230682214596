import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AuthStyles.css';

// Helper function to safely use sessionStorage
const getFromStorage = (key) => {
  if (typeof window !== 'undefined' && window.sessionStorage) {
    return window.sessionStorage.getItem(key);
  }
  return null;
};

function Login() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  
  const handleLogin = () => {
    if (!username.trim() || !password.trim()) {
      setError('Username and password are required');
      return;
    }
    
    // Check credentials against sessionStorage
    const storedUsername = getFromStorage('auth_username');
    const storedPassword = getFromStorage('auth_password');
    
    if (username === storedUsername && password === storedPassword) {
      // Move to PIN verification
      navigate('/authentication-hell/login-pin');
    } else {
      setError('Invalid username or password');
    }
  };

  return (
    <div className="auth-hell-form-container">
      <div className="auth-hell-header-group">
        <h3 className="auth-hell-header-text">Login</h3>
      </div>
      
      {error && (
        <div className="auth-hell-error-container">
          <p className="auth-hell-error-text">{error}</p>
        </div>
      )}
      
      <div className="auth-hell-input-group">
        <label className="auth-hell-input-label">username</label>
        <input
          className="auth-hell-input"
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
            setError('');
          }}
          placeholder=""
        />
      </div>
      
      <div className="auth-hell-input-group">
        <label className="auth-hell-input-label">password</label>
        <input
          className="auth-hell-input"
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setError('');
          }}
          placeholder=""
        />
      </div>
      
      <div className="auth-hell-cursor-button-container">
        <button className="auth-hell-cursor-button" onClick={handleLogin}>
          <div className="auth-hell-cursor"></div>
        </button>
      </div>
      
      <div className="auth-hell-link-container">
        <button 
          className="auth-hell-link-button" 
          onClick={() => navigate('/authentication-hell')}
        >
          back to home
        </button>
      </div>
    </div>
  );
}

export default Login; 