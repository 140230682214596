import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AuthStyles.css';

function LoginSuccess() {
  const navigate = useNavigate();
  
  return (
    <div className="auth-hell-form-container">
      <div className="auth-hell-header-group">
        <h3 className="auth-hell-header-text">login successful</h3>
      </div>
      
      <div className="auth-hell-success-container">
        <p className="auth-hell-success-text">
          You have successfully logged in!
        </p>
        <p className="auth-hell-success-text">
          Your session is now active.
        </p>
      </div>
      
      <div className="auth-hell-link-container">
        <button 
          className="auth-hell-link-button" 
          onClick={() => navigate('/authentication-hell')}
        >
          back to home
        </button>
      </div>
    </div>
  );
}

export default LoginSuccess; 