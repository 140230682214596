import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import { ThemeProvider } from './ThemeContext';
import ThemeToggle from './components/ThemeToggle';

// Authentication Hell components
import AuthLayout from './authentication-hell/AuthLayout';
import AuthHome from './authentication-hell/AuthHome';
import Signup from './authentication-hell/Signup';
import SignupPin from './authentication-hell/SignupPin';
import SignupEmail from './authentication-hell/SignupEmail';
import SignupSecurity from './authentication-hell/SignupSecurity';
import SignupSuccess from './authentication-hell/SignupSuccess';
import Login from './authentication-hell/Login';
import LoginPin from './authentication-hell/LoginPin';
import LoginEmail from './authentication-hell/LoginEmail';
import LoginSecurity from './authentication-hell/LoginSecurity';
import LoginSuccess from './authentication-hell/LoginSuccess';

// Layout component for the main site
function MainLayout({ children }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <header className="header">
        <div className="header-content">
          <h1 className="logo">Xander Jennie</h1>
          <nav className={`nav ${menuOpen ? 'open' : ''}`}>
            <Link to="/" onClick={() => setMenuOpen(false)}>Home</Link>
            <Link to="/about" onClick={() => setMenuOpen(false)}>About Me</Link>
            <Link to="/projects" onClick={() => setMenuOpen(false)}>Side Projects</Link>
            <Link to="/contact" onClick={() => setMenuOpen(false)}>Contact</Link>
          </nav>
          <div className="theme-toggle-container">
            <ThemeToggle />
          </div>
          <div className={`menu-toggle ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
            <div className="hamburger"></div>
          </div>
        </div>
      </header>
      <main className="main-content">
        {children}
      </main>
      <footer className="footer">
        <p>&copy; 2025 Xander Jennie. All rights reserved.</p>
      </footer>
    </>
  );
}

function App() {
  return (
    <ThemeProvider>
      <Router>
        <Routes>
          {/* Authentication Hell routes */}
          <Route path="/authentication-hell" element={<AuthLayout />}>
            <Route index element={<AuthHome />} />
            <Route path="signup" element={<Signup />} />
            <Route path="signup-pin" element={<SignupPin />} />
            <Route path="signup-email" element={<SignupEmail />} />
            <Route path="signup-security" element={<SignupSecurity />} />
            <Route path="signup-success" element={<SignupSuccess />} />
            <Route path="login" element={<Login />} />
            <Route path="login-pin" element={<LoginPin />} />
            <Route path="login-email" element={<LoginEmail />} />
            <Route path="login-security" element={<LoginSecurity />} />
            <Route path="login-success" element={<LoginSuccess />} />
          </Route>

          {/* Main site routes */}
          <Route path="/" element={<MainLayout><Home /></MainLayout>} />
          <Route path="/about" element={<MainLayout><About /></MainLayout>} />
          <Route path="/projects" element={<MainLayout><Projects /></MainLayout>} />
          <Route path="/contact" element={<MainLayout><Contact /></MainLayout>} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;