import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './AuthStyles.css';

// Helper function to safely use sessionStorage
const getFromStorage = (key) => {
  if (typeof window !== 'undefined' && window.sessionStorage) {
    return window.sessionStorage.getItem(key);
  }
  return null;
};

function LoginSecurity() {
  const navigate = useNavigate();
  const [securityQuestion, setSecurityQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [error, setError] = useState('');
  
  useEffect(() => {
    // Get the stored security question
    const question = getFromStorage('auth_security_question');
    if (question) {
      setSecurityQuestion(question);
    } else {
      setError('Security question not found. Please sign up first.');
    }
  }, []);

  const handleComplete = () => {
    if (!answer.trim()) {
      setError('Security answer is required');
      return;
    }
    
    // Verify answer against stored value
    const storedAnswer = getFromStorage('auth_security_answer');
    
    if (answer.toLowerCase() === storedAnswer.toLowerCase()) {
      // Complete login
      navigate('/authentication-hell/login-success');
    } else {
      setError('Incorrect answer');
      setAnswer('');
    }
  };

  return (
    <div className="auth-hell-form-container">
      <div className="auth-hell-header-group">
        <h3 className="auth-hell-header-text">security verification</h3>
      </div>
      
      {error && (
        <div className="auth-hell-error-container">
          <p className="auth-hell-error-text">{error}</p>
        </div>
      )}
      
      <div className="auth-hell-info-container">
        <p className="auth-hell-info-text">
          Please answer your security question:
        </p>
        <p className="auth-hell-info-text" style={{ fontWeight: 'bold', color: '#85a8ff' }}>
          {securityQuestion}
        </p>
      </div>
      
      <div className="auth-hell-input-group">
        <label className="auth-hell-input-label">your answer</label>
        <input
          className="auth-hell-input"
          value={answer}
          onChange={(e) => {
            setAnswer(e.target.value);
            setError('');
          }}
          placeholder=""
        />
      </div>
      
      <div className="auth-hell-cursor-button-container">
        <button className="auth-hell-cursor-button" onClick={handleComplete}>
          <div className="auth-hell-cursor"></div>
        </button>
      </div>
    </div>
  );
}

export default LoginSecurity; 