import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AuthStyles.css';

function AuthHome() {
  const navigate = useNavigate();

  return (
    <div className="auth-hell-form-container">
      <div className="auth-hell-header-group">
        <h3 className="auth-hell-header-text">Authentication hell</h3>
      </div>
      
      <div className="auth-hell-info-container">
        <p className="auth-hell-info-text">
          Welcome to Authentication Hell. This is a demonstration of a complex and frustratingly long authentication flow.
        </p>
        <p className="auth-hell-info-text">
          You can:
        </p>
        <ul className="auth-hell-info-list">
          <li>Create a new account with multiple steps</li>
          <li>Login with existing credentials</li>
        </ul>
        <p className="auth-hell-info-text">
          This demo uses sessionStorage to persist your credentials temporarily.
        </p>
      </div>
      
      <div className="auth-hell-actions-container">
        <button 
          className="auth-hell-action-button" 
          onClick={() => navigate('/authentication-hell/signup')}
        >
          Sign Up
        </button>
        <button 
          className="auth-hell-action-button" 
          onClick={() => navigate('/authentication-hell/login')}
        >
          Login
        </button>
      </div>
    </div>
  );
}

export default AuthHome; 