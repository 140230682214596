import React from 'react';
import './styles/Home.css';
import useContent from '../hooks/useContent';

function About() {
  const { content, loading, error } = useContent('about');

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">Error: {error}</div>;

  return (
    <div className="section">
      <h2>{content.title}</h2>
      {content.description.split('\n').map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
      ))}
    </div>
  );
}

export default About;
