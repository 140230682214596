import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AuthStyles.css';

// Helper function to safely use sessionStorage
const getFromStorage = (key) => {
  if (typeof window !== 'undefined' && window.sessionStorage) {
    return window.sessionStorage.getItem(key);
  }
  return null;
};

function LoginPin() {
  const navigate = useNavigate();
  const [pin, setPin] = useState('');
  const [error, setError] = useState('');
  
  const handleDigit = (digit) => {
    if (pin.length < 6) {
      setPin(prev => prev + digit);
    }
  };
  
  const handleDelete = () => {
    setPin(prev => prev.slice(0, -1));
  };
  
  const handleNext = () => {
    if (pin.length !== 6) {
      setError('Please enter a 6-digit PIN');
      return;
    }
    
    // Verify PIN against stored value
    const storedPin = getFromStorage('auth_pin');
    
    if (pin === storedPin) {
      // Move to email verification
      navigate('/authentication-hell/login-email');
    } else {
      setError('Invalid PIN');
      setPin('');
    }
  };

  return (
    <div className="auth-hell-pin-pad-container">
      <div className="auth-hell-pin-display">
        <div className="auth-hell-pin-label">enter your pin.</div>
        <div className="auth-hell-pin-value-container">
          <div className="auth-hell-pin-value">{pin.replace(/./g, '*')}</div>
        </div>
      </div>
      
      {error && (
        <div className="auth-hell-error-container">
          <p className="auth-hell-error-text">{error}</p>
        </div>
      )}
      
      <div className="auth-hell-keypad-container">
        <div className="auth-hell-keypad-grid">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((num) => (
            <div
              key={num}
              className="auth-hell-keypad-button"
              onClick={() => handleDigit(num.toString())}
            >
              <div className="auth-hell-keypad-text">{num}</div>
            </div>
          ))}
          <div className="auth-hell-keypad-button-empty"></div>
          <div
            className="auth-hell-keypad-button"
            onClick={() => handleDigit('0')}
          >
            <div className="auth-hell-keypad-text">0</div>
          </div>
          <div className="auth-hell-keypad-button-empty"></div>
        </div>
        
        <div className="auth-hell-action-row">
          <div 
            className="auth-hell-action-button" 
            onClick={handleDelete}
          >
            <div className="auth-hell-action-button-text">←</div>
          </div>
          
          <div
            className="auth-hell-action-button" 
            onClick={handleNext}
            style={{opacity: pin.length === 6 ? 1 : 0.5}}
          >
            <div className="auth-hell-action-button-text">→</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPin; 