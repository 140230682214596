import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import './AuthStyles.css';

function AuthLayout() {
  const navigate = useNavigate();

  // Add auth-hell-body class to the body element when mounted
  useEffect(() => {
    document.body.classList.add('auth-hell-body');
    
    // Clean up function to remove the class when unmounted
    return () => {
      document.body.classList.remove('auth-hell-body');
    };
  }, []);

  const handleBackToSite = () => {
    navigate('/projects');
  };

  return (
    <>
      <button 
        className="auth-hell-back-to-site" 
        onClick={handleBackToSite}
      >
        back to site
      </button>
      <div className="auth-hell-container">
        <Outlet />
      </div>
    </>
  );
}

export default AuthLayout; 