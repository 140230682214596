import React from 'react';
import { Link } from 'react-router-dom';
import './styles/Home.css';

function Projects() {
  return (
    <div className="section">
      <h2>Side Projects</h2>
      <p>Here are a few of hobby projects I've worked on:</p>
      <ul>
        <li><strong>Duration for Google Tasks: </strong> ‎ A Browser Extension that allows users to set duration for Google Tasks and more.<a href="https://chromewebstore.google.com/detail/duration-for-google-tasks/pegfblpefnhgggncfepjeknhhapddebh" target='_blank' className="auth-link">Try it out</a></li>
        <li><strong>Authentication Hell: </strong>‎ A frustratingly complex authentication flow demo.<Link to="/authentication-hell" className="auth-link">Try it out</Link></li>
        <li><strong>Swiper: </strong> ‎ Work in Progress - simplify the thinking process of what to cook tonight.</li>
      </ul>
    </div>
  );
}

export default Projects;
