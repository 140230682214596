import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AuthStyles.css';

const SECURITY_QUESTIONS = [
  "What was the name of your first pet?",
  "In what city were you born?",
  "What is your mother's maiden name?",
  "What high school did you attend?",
  "What was the make of your first car?",
  "What is your favorite movie?",
  "What is the name of your favorite childhood teacher?",
  "What is your favorite book?"
];

// Helper function to safely use sessionStorage
const saveToStorage = (key, value) => {
  if (typeof window !== 'undefined' && window.sessionStorage) {
    window.sessionStorage.setItem(key, value);
  }
};

function SignupSecurity() {
  const navigate = useNavigate();
  const [selectedQuestion, setSelectedQuestion] = useState(SECURITY_QUESTIONS[0]);
  const [answer, setAnswer] = useState('');
  const [error, setError] = useState('');
  
  const handleComplete = () => {
    if (!answer.trim()) {
      setError('Security answer is required');
      return;
    }
    
    // Store in sessionStorage
    saveToStorage('auth_security_question', selectedQuestion);
    saveToStorage('auth_security_answer', answer);
    
    // Complete signup
    navigate('/authentication-hell/signup-success');
  };

  return (
    <div className="auth-hell-form-container">
      <div className="auth-hell-header-group">
        <h3 className="auth-hell-header-text">security question</h3>
      </div>
      
      {error && (
        <div className="auth-hell-error-container">
          <p className="auth-hell-error-text">{error}</p>
        </div>
      )}
      
      <div className="auth-hell-questions-container">
        {SECURITY_QUESTIONS.map((question, index) => (
          <div
            key={index}
            className={`auth-hell-question-option ${selectedQuestion === question ? 'auth-hell-selected-question' : ''}`}
            onClick={() => setSelectedQuestion(question)}
          >
            <div className="auth-hell-question-text">
              {question}
            </div>
          </div>
        ))}
      </div>
      
      <div className="auth-hell-input-group">
        <label className="auth-hell-input-label">answer your security question</label>
        <input
          className="auth-hell-input"
          value={answer}
          onChange={(e) => {
            setAnswer(e.target.value);
            setError('');
          }}
          placeholder=""
        />
      </div>
      
      <div className="auth-hell-cursor-button-container">
        <button className="auth-hell-cursor-button" onClick={handleComplete}>
          <div className="auth-hell-cursor"></div>
        </button>
      </div>
    </div>
  );
}

export default SignupSecurity; 