import React, { useContext } from 'react';
import './styles/Home.css';
import { ThemeContext } from '../ThemeContext';
import useContent from '../hooks/useContent';

function Home() {
  const { darkMode } = useContext(ThemeContext);
  const { content, loading, error } = useContent('home');
  
  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">Error: {error}</div>;
  
  return (
    <div className={`home ${darkMode ? 'dark' : 'light'}`}>
      <section className="hero">
        <div className="hero-content">
          <h1 className="hero-title" dangerouslySetInnerHTML={{ __html: content.hero.title }} />
          <p className="hero-subtitle" dangerouslySetInnerHTML={{ __html: content.hero.subtitle }} />
          <a href="projects" className="cta-button">{content.hero.ctaText}</a>
        </div>
        <div className="hero-image">
          <img src={content.hero.heroImage} alt="Illustration" />
        </div>
      </section>
      <section className="skills">
        <div className="skills-content">
          <h2 className="skills-title">{content.skills.title}</h2>
          <p className="skills-subtitle">{content.skills.subtitle}</p>
          <div className="skills-grid">
            {content.skills.cards.map((card, index) => (
              <div className="skill-card" key={index}>
                <img src={card.icon} alt={card.title} />
                <h3>{card.title}</h3>
                <p>{card.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div className="footnote">
        <a href="https://www.flaticon.com/free-icons/web-design" title="web design icons">{content.footnote}</a>
      </div>
    </div>
  );
}

export default Home;