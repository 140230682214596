import { useState, useEffect } from 'react';

// Import all content JSON files
import aboutContent from '../content/about.json';
import homeContent from '../content/home.json';

/**
 * Custom hook for managing website content
 * @param {string} page - The page name to retrieve content for
 * @returns {object} The content for the specified page
 */
const useContent = (page) => {
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    try {
      let pageContent;
      
      // Map page name to corresponding content
      switch (page) {
        case 'about':
          pageContent = aboutContent;
          break;
        case 'home':
          pageContent = homeContent;
          break;
        default:
          throw new Error(`Content for "${page}" page not found`);
      }
      
      setContent(pageContent);
      setLoading(false);
    } catch (err) {
      console.error('Error loading content:', err);
      setError(err.message);
      setLoading(false);
    }
  }, [page]);

  return { content, loading, error };
};

export default useContent; 